:root {
    --bg-color: #121212;
    --card-bg: #1e1e1e;
    --text-color: #ffffff;
    --accent-color: #4caf50;
    --accent-hover: #45a045;
  }
  
  .payment-success-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(135deg, var(--bg-color) 0%, #1e1e1e 100%);
    padding: 2rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: var(--text-color);
  }
  
  .payment-success-card {
    background-color: var(--card-bg);
    border-radius: 12px;
    padding: 2rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
    max-width: 600px;
    width: 100%;
    text-align: center;
  }
  
  .payment-success-card h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .payment-success-details {
    text-align: left;
    background-color: var(--bg-color);
    padding: 1.5rem;
    border-radius: 8px;
    margin-bottom: 1.5rem;
  }
  
  .payment-success-details h3 {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
  }
  
  .payment-success-details p {
    font-size: 1.1rem;
    line-height: 1.5;
    margin: 0.5rem 0;
  }
  
  .payment-success-button {
    background-color: var(--accent-color);
    color: var(--text-color);
    padding: 0.8rem 1.5rem;
    font-size: 1.2rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    outline: none;
  }
  
  .payment-success-button:hover {
    background-color: var(--accent-hover);
    transform: translateY(-2px);
  }
  
  .payment-success-button:disabled {
    background-color: #555;
    cursor: not-allowed;
  }
  