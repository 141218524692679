#header {
    overflow: hidden;
    position: relative;
}

#header.blur-effect::before {
    top: 0;
    right: 0;
    margin-right: -5%;
    margin-top: -5%;
}

#header .header__container {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 20px;
}

#header .intro__text {
    font-size: 200px;
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 1;
    font-weight: 900;
    text-transform: uppercase;
}

#header .column {
    position: relative;
    z-index: 2;
}

#header .intro__container {
    width: 550px;
}

#header .intro__container.blur-effect::before {
    bottom: 0;
    left: 0;
    width: 100px;
    height: 100px;
    margin-left: -5%;
    margin-bottom: -5%;
}

#header .profile__wrapper {
    flex: 1;
}

#header .header__info__top {
    font-size: 15px;
    font-weight: 500;
}

#header .header__title {
    font-size: 62px;
    font-weight: 900;
}

#header .header__description {
    font-size: 15px;
    font-weight: 500;
    margin: 20px 0;
}

#header .header__info__bottom {
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 30px;
}

#header .profile__photo__container {
    width: 400px;
    max-width: 100%;
    position: relative;
    margin: auto;
    overflow: hidden;
}

#header .profile__photo__container::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(transparent, var(--color-ui-1));
}

/* Video Background Styling */
#header .video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    opacity: 1.8; /* Lowering the exposure of the video */
    filter: brightness(50%); /* Further reducing the brightness for low exposure */
}

@media (max-width: 1100px) {
    #header .header__title {
        font-size: 32px;
    }
}

@media (max-width: 850px) {
    #header {
        min-height: auto;
    }

    #header.blur-effect::before {
        display: none;
    }

    #header .header__container {
        flex-direction: column;
        align-items: stretch;
    }

    #header .intro__text {
        font-size: 100px;
    }

    #header .intro__container {
        width: 100%;
    }

    .header__title,
    .header__info__top,
    .header__description {
        text-align: center;
    }

    .header__info__bottom {
        justify-content: center;
        gap: 20px;
    }

    .header__info__bottom .btn {
        width: 45%;
        padding: 15px;
    }
}
