/* Dashboard.css */

.dashboard-container {
    padding: 2rem;
    background: #121212;
    min-height: 100vh;
    color: #e0e0e0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .dashboard-container h1 {
    text-align: center;
    color: #FFA500;
    margin-bottom: 2rem;
  }
  
  .dashboard-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
  }
  
  .dashboard-card {
    background: #1e1e1e;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.6);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  
  .dashboard-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.8);
  }
  
  .dashboard-card h3 {
    margin: 0 0 0.5rem 0;
    color: #FFA500;
  }
  
  .dashboard-card p {
    margin: 0.3rem 0;
    font-size: 0.95rem;
    line-height: 1.4;
  }
  
  .confirmation {
    margin-top: 1rem;
    font-weight: bold;
    color: #ffcc00;
  }
  