#testimonial .section__wrapper{
    display: grid;
    gap: 30px;
    grid-template-columns: 35% 60%;
}

#testimonial .testimonial__container{
    width: 100%;
    padding-bottom: 4rem !important;
}

/* Remove the client avatar */
#testimonial .client__avatar{
    display: none; /* Hides the profile picture */
}

#testimonial .testimonial{
    background: var(--color-ui-2);
    text-align: center;
    padding: 20px;
    border-radius: var(--border-radius-4);
    user-select: none;
}

#testimonial .client__review{
    color: var(--color-text);
    font-weight: 300;
    font-size: 15px;
    display: block;
    width: 80%;
    margin:0.8rem auto 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet{
    background: var(--color-primary);
}

@media (max-width:950px){
    #testimonial .section__wrapper{
        grid-template-columns: 100%;
    }
}
