/* Root Variables */
:root {
  --accent: #FFA500;
  --accent-light: #818181;
  --bg-dark: #121212;
  --card-bg: #1e1e1e;
  --text-light: #e0e0e0;
  --shadow-color: rgba(0, 0, 0, 0.6);
  --gradient-border: linear-gradient(135deg, var(--accent), var(--accent-light));
}

/* Main Dashboard Container */
.admin-dashboard {
  padding: 2rem;
  background: var(--bg-dark);
  min-height: 100vh;
  color: var(--text-light);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.admin-dashboard h1 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--accent);
}

/* Filter Section */
.filter-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
}

.filter-section label {
  font-weight: 600;
}

.filter-section input[type="date"] {
  padding: 0.5rem 0.75rem;
  background: var(--card-bg);
  border: 1px solid #333;
  color: var(--text-light);
  border-radius: 4px;
  outline: none;
  transition: border 0.3s ease;
}

.filter-section input[type="date"]:focus {
  border-color: var(--accent);
}

/* Create Event Button */
.add-project-btn {
  background: var(--gradient-border);
  background-size: 200% 200%;
  color: #fff;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 30px;
  cursor: pointer;
  margin: 0 auto 1.5rem;
  display: block;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.add-project-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px var(--shadow-color);
}

/* Create Event Form */
.create-event-form {
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  border-radius: 8px;
  background: var(--card-bg);
  box-shadow: 0 2px 8px var(--shadow-color);
  animation: fadeIn 0.5s ease;
}

.create-event-form h2 {
  text-align: center;
  color: var(--accent);
  margin-bottom: 1rem;
  font-size: 1.8rem;
}

.create-event-form label {
  display: block;
  font-weight: 600;
  margin: 0.5rem 0 0.2rem;
}

.create-event-form input,
.create-event-form textarea {
  width: 100%;
  padding: 0.5rem 0.75rem;
  margin-bottom: 0.8rem;
  background: var(--bg-dark);
  border: 1px solid #333;
  border-radius: 4px;
  color: var(--text-light);
  transition: border 0.3s ease;
  outline: none;
}

.create-event-form input:focus,
.create-event-form textarea:focus {
  border-color: var(--accent);
}

.create-btn {
  background: var(--gradient-border);
  background-size: 200% 200%;
  color: #fff;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: block;
  margin: 0 auto;
}

.create-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px var(--shadow-color);
}

/* Events List */
.project-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
}

.no-events {
  text-align: center;
  font-size: 1.2rem;
  color: #aaa;
}

/* Event Card (Project Item) */
.project-item {
  background: var(--card-bg);
  border-radius: 8px;
  padding: 1.5rem;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 14px var(--shadow-color), 0 0 0 2px var(--accent);
}

/* Header: Title and Delete Button */
.project-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.project-item-header h3 {
  margin: 0;
  font-size: 1.4rem;
  color: var(--accent);
}

.delete-btn {
  background: transparent;
  border: none;
  color: #ff4444;
  font-size: 1.2rem;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.delete-btn:hover {
  transform: scale(1.1);
}

/* Expandable Event Details */
.event-details {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

.project-item.expanded .event-details {
  max-height: 1000px; /* large enough value */
  opacity: 1;
  margin-top: 1rem;
}

/* Detail Rows for Structured Info */
.detail-row {
  display: flex;
  justify-content: space-between;
  padding: 0.3rem 0;
  border-bottom: 1px solid #333;
  font-size: 0.9rem;
}

.detail-label {
  font-weight: 600;
  color: var(--accent);
}

/* Event URL Display & Copy Button */
.event-url {
  margin-top: 0.8rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.url-text {
  margin-left: 0.5rem;
  font-size: 0.9rem;
  word-break: break-all;
}

.copy-btn {
  margin-left: 8px;
  cursor: pointer;
  background: transparent;
  border: none;
  color: var(--accent);
  font-size: 1.2rem;
  transition: transform 0.2s ease;
}

.copy-btn:hover {
  transform: scale(1.1);
}

/* Registration List */
.registration-list {
  margin-top: 1rem;
  background: #2a2a2a;
  padding: 1rem;
  border-radius: 6px;
}

.registration-list h4 {
  margin: 0 0 0.5rem;
  color: var(--accent);
}

.registrant-item {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.no-registrations {
  font-size: 0.9rem;
  color: #aaa;
}

/* Fade In Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.delete-registration-btn {
  background: #ff4444; /* Bold red for delete */
  color: #fff;
  border: none;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  font-size: 0.85rem;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
  margin-top: 0.5rem;
}

.delete-registration-btn:hover {
  background: #e63946; /* Slightly darker red */
  transform: scale(1.05);
}
.registration-count {
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--accent);
  margin-bottom: 0.5rem;
}

.delete-registration-btn {
  background: #ff4444; /* Bold red for delete */
  color: #fff;
  border: none;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  font-size: 0.85rem;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
  margin-top: 0.5rem;
}

.delete-registration-btn:hover {
  background: #e63946; /* Slightly darker red */
  transform: scale(1.05);
}
