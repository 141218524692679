:root {
  --accent: #FFA500;
  --accent-light: #FFB347;
  --bg-dark: #121212;
  --card-bg: #1e1e1e;
  --text-light: #e0e0e0;
  --shadow-color: rgba(0, 0, 0, 0.6);
  --gradient-border: linear-gradient(135deg, var(--accent), var(--accent-light));
  --transition: 0.3s ease;
}

/* Global Background Gradient Animation */
@keyframes backgroundShift {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Gradient Animation for Buttons */
@keyframes gradientShift {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Fade In Animation for Cards */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Floating Shape Animation */
@keyframes float {
  0% {
    transform: translateY(0) translateX(0);
  }
  50% {
    transform: translateY(-20px) translateX(20px);
  }
  100% {
    transform: translateY(0) translateX(0);
  }
}

/* Main Events Page Container */
.events-page {
  position: relative; /* needed for floating elements */
  /* Subtle animated gradient background */
  background: linear-gradient(135deg, #1a1a1a, #121212, #1a1a1a);
  background-size: 400% 400%;
  animation: backgroundShift 15s ease infinite;
  min-height: 100vh;
  padding: 2rem;
  color: var(--text-light);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  overflow: hidden;
}

/* Floating container for extra moving parts */
.floating-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
  z-index: 0;
}

/* Floating shapes */
.floating-shape {
  position: absolute;
  background: var(--accent);
  opacity: 0.15;
  border-radius: 50%;
  animation: float 6s ease-in-out infinite;
}

/* Banner Wrapper */
.banner-wrapper {
  position: relative;
  width: 100%;
  height: 300px; /* Adjust height as needed */
  overflow: hidden;
  margin-bottom: 2rem;
  z-index: 1;
}

.banner-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.2); /* low exposure */
  z-index: 0;
}

.banner-content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 1rem;
}

/* Title Styling */
.events-title {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 2rem;
  color: var(--accent);
  font-weight: bold;
}

/* Filter Section */
.events-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
}

.filter-label {
  font-weight: 600;
}

.filter-date-input {
  background: var(--card-bg);
  border: 1px solid #333;
  color: var(--text-light);
  border-radius: 4px;
  padding: 0.5rem 0.75rem;
  outline: none;
  transition: border var(--transition), transform var(--transition);
}

.filter-date-input:focus {
  border-color: var(--accent);
  transform: scale(1.03);
}

/* Grid Layout */
.events-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
  position: relative;
  z-index: 1;
}

.no-events-text {
  text-align: center;
  color: #aaa;
  margin-top: 3rem;
}

/* Event Card */
.event-card {
  background: var(--card-bg);
  border-radius: 8px;
  padding: 1.5rem;
  cursor: pointer;
  transition: transform var(--transition), box-shadow var(--transition);
  position: relative;
  overflow: hidden;
  animation: fadeIn 0.6s ease forwards;
  z-index: 1;
}

/* Animated pseudo-element for a moving border effect */
.event-card::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(45deg, transparent, var(--accent), transparent);
  animation: spin 6s linear infinite;
  z-index: -1;
  opacity: 0.15;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.event-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 14px var(--shadow-color), 0 0 0 2px var(--accent);
}

/* Card Header & Summary */
.card-header {
  margin-bottom: 0.8rem;
}

.event-title {
  color: var(--accent);
  margin-bottom: 0.3rem;
  font-size: 1.4rem;
}

.event-date,
.event-time,
.event-location {
  margin: 0.2rem 0;
  font-size: 0.9rem;
  color: var(--text-light);
}

.card-summary {
  margin-bottom: 0.8rem;
}

.event-description {
  margin-top: 0.5rem;
  color: #ccc;
  font-size: 0.9rem;
}

/* Expandable Details */
.event-details {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, opacity 0.5s ease;
  margin-top: 0.8rem;
}

.event-card.expanded .event-details {
  max-height: 1000px; /* sufficiently high */
  opacity: 1;
}

/* Detail Rows for Structured Information */
.detail-row {
  display: flex;
  justify-content: space-between;
  padding: 0.3rem 0;
  border-bottom: 1px solid #333;
  font-size: 0.9rem;
}

.detail-label {
  font-weight: 600;
  color: var(--accent);
}

/* Register Button */
.register-btn {
  margin-top: 1rem;
  background: var(--gradient-border);
  background-size: 200% 200%;
  border: none;
  color: #fff;
  padding: 0.6rem 1.2rem;
  border-radius: 30px;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: block;
  width: 100%;
  text-align: center;
  animation: gradientShift 3s ease infinite;
  position: relative;
  z-index: 1;
}

.register-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 10px var(--shadow-color);
}
