/* Global Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --orange: #FFA500;
  --orange-light: #FFB347; /* lighter variant */
  --bg-light: #000000;
  --text-dark: #a0a0a0;
  --shadow-color: rgba(0, 0, 0, 0.2);
}

/* Container with transparent black background and animated gradient overlay */
.login-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin-top: 0; /* Adjust if your navbar is fixed */
  background: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  padding: 2rem;
}

.login-container::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(45deg, var(--orange), var(--orange-light), var(--orange), var(--orange-light));
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
  z-index: -1;
  opacity: 0.3;
}

@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Fade in and slide-up animation for the login box */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.login-box {
  position: relative;
  background: var(--bg-light);
  padding: 2rem;
  border-radius: 12px;
  width: 320px;
  box-shadow: 0 4px 16px var(--shadow-color);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  animation: fadeInUp 0.6s ease-out;
  overflow: hidden;
}

/* Animated gradient border around the login box */
.login-box::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, var(--orange), var(--orange-light), var(--orange), var(--orange-light));
  background-size: 400% 400%;
  z-index: -1;
  border-radius: 14px;
  animation: gradientAnimation 10s ease infinite;
}

.login-box h2 {
  margin: 0 0 1rem 0;
  text-align: center;
  color: var(--orange);
  font-size: 1.75rem;
  font-weight: 700;
}

.login-box form {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.login-box label {
  font-weight: 600;
  color: var(--text-dark);
}

/* Input group to accommodate icons */
.input-group {
  position: relative;
}

.input-group .icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--orange);
  font-size: 1.1rem;
}

.input-group input {
  width: 100%;
  padding: 0.6rem 0.6rem 0.6rem 2.5rem; /* extra left padding for the icon */
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  color: var(--text-dark);  /* Ensures input text is visible */
  background-color: #fff;
  transition: border 0.3s ease, box-shadow 0.3s ease;
}

.input-group input:focus {
  outline: none;
  border-color: var(--orange);
  box-shadow: 0 0 5px var(--orange);
}

/* Animated gradient button from orange to black with more rounded corners */
.login-btn {
  padding: 0.8rem 1rem;
  background: linear-gradient(45deg, var(--orange), #000);
  background-size: 200% 200%;
  animation: buttonGradient 5s ease infinite;
  color: #fff;
  border: none;
  border-radius: 30px; /* More rounded */
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  transition: transform 0.2s ease;
}

.login-btn:hover {
  transform: translateY(-2px);
}

@keyframes buttonGradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Fade-in animation for authentication messages */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.auth-message {
  color: #d62828;
  font-weight: 600;
  text-align: center;
  animation: fadeIn 0.5s ease-in;
}

.toggle-text {
  text-align: center;
  color: var(--text-dark);
  font-size: 0.9rem;
}

.toggle-text span {
  color: var(--orange);
  font-weight: 600;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.2s ease;
}

.toggle-text span:hover {
  color: var(--orange-light);
  transform: scale(1.05);
}
